import { Component,inject,OnInit } from '@angular/core';
import { RestapiService } from '../Services/restapi.service';
import { environment } from 'src/environments/environment';
import { Storage, ref, uploadBytesResumable,getDownloadURL } from '@angular/fire/storage';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
// export interface PeriodicElement {
//   name: string;
//   position: number;
//   weight: number;
//   symbol: string;
// }
export class NewsComponent implements OnInit {
  constructor(
    private restApi:RestapiService,
    private storage: Storage = inject(Storage),
    private cdr: ChangeDetectorRef

  ){

  }
  appUrl:any=environment.appUrl
  newsList:any=[]
  ngOnInit(): void {
    this.getNews()
  }
  offset:any=0;
  limit:any=100;
  p:any=0;
  getNews(){
    // this.loader=true
    this.restApi.getNews(this.offset,this.limit).subscribe((res)=>{
      // this.loader=false
      console.log(res)
      if(res){
        this.newsList=res
      }else{
        this.newsList=[]
      }
    })
  }
  bannerImage:any=''
  imageupload_progress:any=''
  onuploadImage(e:any){
    let file = e.target.files
    console.log(e,file)
    if (file){
      // const storage = getStorage();
      // const storageref = ref(storage,`images/` + file[0].name);
      // const uploadTask = uploadBytesResumable(storageref,file);
      const storageRef = ref(this.storage,file[0].name);
      const task = uploadBytesResumable(storageRef,file[0])
      task.on('state_changed',(snapshot)=>{
        const progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        this.imageupload_progress='Uploading the image'
        console.log('upload is '+progress+'% done');
        switch(snapshot.state){
          case 'paused':
            this.imageupload_progress='Image Upload paused'
            console.log('upload paused')
            break;
          case 'running':
            console.log('upload is running')
            break;
          
        }
      },
      (error)=>{
        this.imageupload_progress='Image upload failed call shashank now.'
      },
      ()=>{
        getDownloadURL(task.snapshot.ref).then((downloadUrl)=>{
          this.imageupload_progress='uploaded Image'
          setTimeout(()=>{
              this.imageupload_progress=''
              this.cdr.detectChanges()
          },1000)
          console.log('File uploaded! download URL is ',downloadUrl);
          this.editData.image_url=downloadUrl;
          this.bannerImage = downloadUrl
          this.cdr.detectChanges();

          console.log(this.editData)
        })
      }
      )
    }
  }
  showModalEdit:boolean=false
  editData:any={
    id:'',
    title:'',
    sponsered:'',
    sentiment:'',
    category:'',
    image_url:''
  }
  domainType:any=''
  showEdit(data:any){
    this.showModalEdit=true
    console.log(data)
    this.editData = data;
  }

  searchByName:any='';
  filterOption:any='title'
  filterdate:any
  domain_names:any=[
    'blockchainreporter.net',
    'coindesk.com',
    'dlnews.com'
  ];
  closeModal(){
    this.showModalEdit=false
  }
  searchByName_(){
    if(this.searchByName!=undefined && this.searchByName!='' && this.searchByName!=null){
      this.newsList = this.newsList.filter((res:any)=>{
        return res.title.toLocaleLowerCase().match(this.searchByName.toLocaleLowerCase()) 
      })
    }else{
    this.getNews()
    }
  }
  createError:any=''
  enableEdit:boolean=false
  UpdateNews(type:any){
    console.log(this.editData)
    if(type=='create'){

    if(this.editData.title==undefined || this.editData.title=='' || this.editData.title==null){
      this.createError="Please Enter Title";
    }else if(this.editData.sentiment == undefined || this.editData.sentiment==null || this.editData.sentiment == ''){
      this.createError = "Please Choose Sentiment"
    }else if(this.editData.sponsered==undefined || this.editData.sponsered == null || this.editData.sponsered == ''){
      this.createError='Please Choose whether the post is sponsored or not'
    }else if(this.editData.category == undefined || this.editData.category == '' || this.editData.category == null){
      this.createError = "Please Choose Category"
    }else if(this.editData.summary == undefined || this.editData.summary == null || this.editData.summary==''){
      this.createError = "Please Summarize the news article in 60 words";
    }else{
      // if(type=='create'){

      
      this.createError=''
      let news_body={
        "title": this.editData.title,
        "summary": this.editData.summary,
        "category": this.editData.category,
        "sponsored": this.editData.sponsered=="true"?true:false,
        "sentiment": this.editData.sentiment,
        "language": "en",
        "maintext":"NULL",
        "image_url":this.editData.image_url,
        "title_page":this.editData.title,
        "authors":[]
    }
      this.restApi.createNews(news_body).subscribe((res:any)=>{
        console.log(res)
        this.showModalCreate=false
        alert('created news')
        this.getNews()
      })
    }
  
    }
    else if(type=='update'){
      if(this.editData.title==undefined || this.editData.title=='' || this.editData.title==null){
        this.createError="Please Enter Title";
      }else if(this.editData.summary == undefined || this.editData.summary == null || this.editData.summary==''){
        this.createError = "Please Summarize the news article in 60 words";
      }else{
      let news_body=  {
        "title": this.editData.title,
        "summary":  this.editData.summary,
        "image_url":this.editData.image_url,
        "id":this.editData.id
    }
    this.createError=''
    this.restApi.updateNews(news_body).subscribe((res)=>{
      console.log(res)
      this.showModalEdit=false
      this.enableEdit=false
      alert('Updated news')
      this.getNews()
    })
    }
  }
    // if(this.createError==''){

    // }

   
  }
  loader:boolean=false
backupArray:any=[]
  OnSelectDomain(){
    console.log(this.domainType)
    if(this.domainType!=''){
      // this.getNews()
      if(this.backupArray.length==0){
      this.backupArray = this.newsList
      }
    this.newsList=this.backupArray.filter((res:any)=>{
      return res.source_domain.toLocaleLowerCase().match(this.domainType.toLocaleLowerCase())
    })
    }else{
      this.getNews()
    }
  
  }

  isToday(datestring:string):boolean{
    const today = new Date();
    const date = new Date(datestring)
    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
  }
  OnchangeDate(){
    console.log(this.filterdate)
    if (this.filterdate!=''){
    if (this.backupArray.length==0){
      this.backupArray = this.newsList
    }
    let selectedDate = new Date(this.filterdate)
    this.newsList = this.backupArray.filter((res:any)=>{
      return new Date(res.date_publish).toDateString() === selectedDate.toDateString()
    })
  }else{
    this.getNews()
  }
  }
  CreateNews(){
    this.showModalCreate=true
    this.editData={}
  }
  showModalCreate:boolean=false
  closeModalCreate(){
    this.showModalCreate=false
  }
  showDelete_:boolean=false
  delete_slug:any=''
  showDelete(news:any){
    this.showDelete_=true
    this.delete_slug = news["slug"]
  }
  closeModalDelete(){
    this.showDelete_=false
  }
  DeleteNews(){
    this.restApi.deletenews(this.delete_slug).subscribe((res:any)=>{
      console.log(res)
      this.closeModalDelete()
      this.showModalEdit=false
      alert('Deleted Successfully!')
      this.getNews()
    })
  }

  removeImage(){
    this.editData.image_url=''
    this.bannerImage=''
  }
}
