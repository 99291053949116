import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RestapiService {


  constructor(
    private ngFireStore:AngularFirestore,
    private http:HttpClient
  ) { }

  getContactDetails(){
    return this.ngFireStore.collection('contact').valueChanges()
  }

  getNews(offset:any,limit:any){
    return this.http.get(environment.lambdaUrl+'/dev/getnews?limit='+limit+'&offset='+offset)
  }

  createNews(news:any){
    let header=new HttpHeaders()
    header.append('Content-Type','application/json')
    header.append('Accept','*/*')
   return  this.http.post(environment.lambdaCreate+'/dev/create',news,{headers:header})
  }

  updateNews(news:any){
    let header = new HttpHeaders()
    header.append('Content-Type','application/json')
    header.append('Accept','*/*')
    return  this.http.post(environment.lambdaCreate+'/dev/edit',news,{headers:header})
  }
  deletenews(slug:any){
    return this.http.delete(environment.lambdaDelete+'/dev/delete?slug='+slug)
  }
}
