import { Component } from '@angular/core';
import { LoginService } from '../Services/login.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  constructor(
    private loginService:LoginService,
    private router:Router
  ){

  }
  emailId:any='';
  password:any='';

  Login(){
    console.log(this.emailId,this.password)
  }
  user:any={
    'email':'',
    'password':''
  }
  onChangeField(e:any,type:any){
    if(type=='email'){
      this.user["email"] = e.target.value
    }else if(type=='password'){
      this.user["password"] = e.target.value
    }

  }
  emailError:any=''
  passwordError:any=''
  LoginUser(){
    try{
    this.user={
      'email':this.emailId,
      'password':this.password
    }
if(this.user["email"]=='' || this.user["email"]==null || this.user["email"]==undefined){
  this.emailError="Email Address is required"
  this.passwordError=''
}else if(
  this.user["password"]  == '' || this.user["password"] == null || this.user["password"] == undefined
){
  this.passwordError = "Password is required"
  this.emailError=''
}else{
  this.emailError=''
  this.passwordError=''
  this.loginService.SignIn(this.user["email"],this.user["password"]).then((res)=>{
    console.log(res)
    this.router.navigate(['/dashboard'])
  }).catch(e=>{alert('Wrong Email Id/Password')})
}
  }catch(e){
    console.log(e)
    alert('Wrong credentials!')
  }
}



}
