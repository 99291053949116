export const environment = {
  firebase: {
    apiKey: "AIzaSyDVdlTrMd2tDt-n1OkPKR150Ay8Z9fDog4",
    authDomain: "blockpanda-admin.firebaseapp.com",
    projectId: "blockpanda-admin",
    storageBucket: "blockpanda-admin.appspot.com",
    messagingSenderId: "432857492196",
    appId: "1:432857492196:web:9956ca0573ccf77a1d9af1",
    measurementId: "G-5VLRYEKTSF"
  },
  lambdaUrl:'https://25cnc1o3b2.execute-api.us-east-1.amazonaws.com',

  lambdaCreate:"https://25cnc1o3b2.execute-api.us-east-1.amazonaws.com",
  lambdaDelete:'https://25cnc1o3b2.execute-api.us-east-1.amazonaws.com',
  appUrl:'https://www.blockpanda.co/'
};